<script setup>
/* eslint-disable no-unused-vars */
import { reactive, ref, onMounted, watch } from "vue";
import { ElNotification } from "element-plus";
import * as article from "@/http/article";
import ImageUploader from "quill-image-uploader";
import { useRoute, useRouter } from "vue-router";

const router = useRouter();
const route = useRoute();
const { params } = route;
const loading = ref(false);
const error = ref(false);
const ruleFormRef = ref();
const fileList = ref([]);
const url = ref(null);
const contentRef = ref(null);
const ruleForm = reactive({
  title: "",
  description: "",
  price: 0,
  thumbnail: null,
  content: null,
  images: [],
});
const step = ref(1);
const rules = reactive({
  title: [
    {
      required: true,
      message: "Veuillez saisir le nom de l'article",
      trigger: "blur",
    },
  ],
  content: [
    {
      required: true,
      message: "Veuillez saisir une description",
      trigger: "blur",
    },
  ],
  thumbnail: [
    {
      required: true,
      message: "Veuillez selectionner une image",
      trigger: "blur",
    },
  ],
  price: [
    { required: true, message: "Veuillez entrer le prix", trigger: "blur" },
  ],
});

const toolbarOptions = reactive([
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ["bold", "italic", "underline", "strike"],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ align: [] }],
  [{ font: [] }],
  [{ size: ["small", false, "large", "huge"] }],
  ["blockquote", "code-block"],
  ["link", "image"],
  ["clean"],
]);

const modules = {
  name: "imageUploader",
  module: ImageUploader,
  options: {
    upload: (file) => {
      return new Promise((resolve, reject) => {
        const data = new FormData();
        data.append("image", file);
        article
          .setArticleImage(data)
          .then((res) => {
            ruleForm.images.push(res.id);
            resolve(res.url);
          })
          .catch((err) => {
            ElNotification({
              message: "Une erreur est survenue !",
              type: "error",
            });
            reject("Upload failed");
          });
      });
    },
  },
};

onMounted(() => {
  params.id ? getArticle(params.id) : null;
});

const getArticle = async (id) => {
  try {
    loading.value = true;
    const response = await article.getArticle(id);
    ruleForm.title = response.title;
    ruleForm.description = response.description;
    ruleForm.price = response.price;
    url.value = response.url;
    ruleForm.content = response.content;
  } catch (error) {
    ElNotification({
      title: "Erreur",
      message: "Oops. L'article n'a pas pu être chargés",
      type: "error",
    });
  } finally {
    loading.value = false;
  }
};

const createArticle = async () => {
  loading.value = true;
  const formData = new FormData();
  formData.append("QWERTYUIOP", ruleForm);
  formData.append("title", ruleForm.title);
  formData.append("description", ruleForm.description);
  formData.append("price", ruleForm.price);
  formData.append("content", JSON.stringify(ruleForm.content));
  ruleForm.thumbnail ? formData.append("thumbnail", ruleForm.thumbnail) : null;
  formData.append("images", JSON.stringify(ruleForm.images));
  if (params?.id) {
    await article
      .edit(params?.id, formData)
      .then(() => {
        ElNotification({
          message: "Article mis à jour !",
          type: "success",
        });
        router.push({ name: "articles" });
      })
      .catch(() => {
        ElNotification({
          message: "Une erreur est survenue !",
          type: "error",
        });
        error.value = true;
      });
  } else {
    await article
      .create(formData)
      .then(() => {
        ElNotification({
          message: "Article créé !",
          type: "success",
        });
        router.push({ name: "articles" });
      })
      .catch(() => {
        ElNotification({
          message: "Une erreur est survenue !",
          type: "error",
        });
        error.value = true;
      });
  }
  loading.value = false;
};

const handleSelection = (file) => {
  if (file.size > 1000000) {
    ElNotification({
      title: "Erreur",
      message: "La taille de l'image ne doit pas excéder 1MB",
      type: "error",
    });
    fileList.value = [];
  } else {
    ruleForm.thumbnail = file.raw;
    if (fileList.value.length > 0) {
      fileList.value = [];
      fileList.value.push(file);
    }
  }
};
</script>

<template>
  <!-- Breadcrumb -->
  <div class="d-flex justify-content-between align-items-center">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-uppercase">
          <span href="#">FINDLOVE</span>
        </li>
        <li class="breadcrumb-item active text-dark" aria-current="page">
          Articles
        </li>
      </ol>
    </nav>
  </div>
  <hr class="mb-5" />
  <!-- End Breadcrumb -->
  <Loader v-if="loading" />
  <div v-if="!loading">
    <el-alert
      v-if="error"
      title="Vérifiez que tous les champs demandés sont bien remplis et respectent le bon format"
      type="error"
    />
    <el-form
      :model="ruleForm"
      label-width="auto"
      label-position="top"
      ref="ruleFormRef"
      :rules="rules"
    >
      <div v-if="step === 1">
        <el-form-item label="Titre" prop="title">
          <el-input v-model="ruleForm.title" size="large"></el-input>
        </el-form-item>
        <el-form-item label="Description" prop="description">
          <el-input v-model="ruleForm.description" size="large"></el-input>
        </el-form-item>
        <el-form-item class="w-100" label="Photo" prop="thumbnail">
          <el-upload
            v-model:file-list="fileList"
            accept="image/*"
            :auto-upload="false"
            @change="handleSelection"
            :multiple="false"
            list-type="picture"
            drag
          >
            <img
              v-if="url && fileList.length === 0"
              :src="url"
              width="200"
              height="200"
            />
            <div class="el-upload__text">
              Glisser une image ici ou <em>Cliquer pour sélectionner</em>
            </div>
            <template #tip>
              <div class="el-upload__tip">Image jpg/png de moins de 1MB</div>
            </template>
          </el-upload>
        </el-form-item>
        <el-form-item label="Prix" prop="price">
          <el-input v-model.number="ruleForm.price" size="large"></el-input>
        </el-form-item>
      </div>
      <div v-if="step === 2">
        <el-form-item label="Contenu" prop="content">
          <QuillEditor
            ref="contentRef"
            :modules="modules"
            :toolbar="toolbarOptions"
            contentType="html"
            placeholder="Commencer la saisie ici"
            theme="snow"
            class="w-100 h-100"
            v-model:content="ruleForm.content"
          />
        </el-form-item>
      </div>
    </el-form>
    <div>
      <Loader v-if="loading" />
      <span v-else class="dialog-footer">
        <el-button v-if="step === 2" type="primary" @click="step--"
          >Précédent</el-button
        >
        <el-button v-if="step === 1" type="primary" @click="step++"
          >Suivant</el-button
        >
        <el-button v-if="step === 2" type="primary" @click="createArticle">{{
          params.id != null ? "Modifier un article" : "Ajouter un article"
        }}</el-button>
      </span>
    </div>
  </div>
</template>

<style scoped>
input:focus {
  box-shadow: inherit !important;
}
</style>
